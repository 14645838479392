import { render } from "./TilePhotos.vue?vue&type=template&id=10955aa2"
import script from "./TilePhotos.ts?vue&type=script&lang=ts"
export * from "./TilePhotos.ts?vue&type=script&lang=ts"

import "./tilePhotos.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "10955aa2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('10955aa2', script)) {
    api.reload('10955aa2', script)
  }
  
  module.hot.accept("./TilePhotos.vue?vue&type=template&id=10955aa2", () => {
    api.rerender('10955aa2', render)
  })

}

script.__file = "src/components/TilePhotos/TilePhotos.vue"

export default script